import { useToast } from "@/components/ui/use-toast";
import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"
import ms from "ms";
 
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function classNames(...classes: string[]) {
  const uniqueClasses = Array.from(new Set(classes.join(" ").split(" ")));
  return uniqueClasses.join(" ");
}

export const timeAgo = (timestamp?: Date): string => {
  if (!timestamp) return "Just now";
  const diff = Date.now() - new Date(timestamp).getTime();
  if (diff < 60000) {
    // less than 1 second
    return "Just now";
  } else if (diff > 82800000) {
    // more than 23 hours – similar to how Twitter displays timestamps
    return new Date(timestamp).toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year:
        new Date(timestamp).getFullYear() !== new Date().getFullYear()
          ? "numeric"
          : undefined,
    });
  }
  return `${ms(diff)} ago`;
};

export function addHttpIfMissing(url: string) {
  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    return "http://" + url;
  }
  return url;
}

export function absoluteUrl(path: String) {
  return `${process.env.NEXT_PUBLIC_APP_URL}${path}`
}

interface ParagraphData {
  headings: string[];
  sentences: string[];
}

// handle the potential errors using try-catch block
export function consolidateParagraphs(data: ParagraphData[]): string {

  let paragraph = '';
  let latestHeading = '';

  try {
    for(let i = 0; i < data.length; i++) {

      if(data[i].headings.length > 0) {
        latestHeading = data[i].headings.join(' ');
        paragraph += `\n\n${latestHeading}\n\n`;
      }

      const sentences = data[i].sentences;
      paragraph += sentences.join(' ');
    }
  } catch (error) {
    console.warn("processing paragraph issues", error);
  }

  return paragraph;
}

export function capitalize(str: string | null) {
  if (!str || typeof str !== "string") return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const delay = (ms:number) => new Promise(res => setTimeout(res, ms));
